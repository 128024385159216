import React, { useEffect } from "react";
import { Helmet } from "react-helmet"; // Add this line
import styles from "./NewHomePage.module.css";

const NewHomePage = () => {
  useEffect(() => {
    const fadeElements = document.querySelectorAll(`.${styles.fadeIn}`);
    fadeElements.forEach((el) => {
      el.style.opacity = 1;
    });
  }, []);
  return (
    <>
      <head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DN8WG4BTMY"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-DN8WG4BTMY');
          `}
        </script>
      </head>

      <div className={styles.App}>
        <nav className={styles.navbar}>
          <h1 className={styles.brand}>Inika</h1>
          <div className={styles.navLinks}>
            <a href="/shopify-login">InikaTag</a>
            <a href="/Demo">Demo</a>
          </div>
        </nav>
        <div className={styles.container}>
          <div className={styles.fadeContainer}>
            <h1 className={`${styles.fadeIn}`}>Launching Soon</h1>
            <p className={`${styles.fadeIn}`}>
              Tech to revolutionize the e-commerce industry.
            </p>
            <p className={`${styles.fadeIn}`} style={{ marginBottom: "40px" }}>
              We built Inika to make sure innovation works for you, your
              business, and your people.
            </p>
            <a
              href="mailto:ykothari@inika.app"
              className={`${styles.contactButton} ${styles.fadeIn}`}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHomePage;
